import "@/assets/vendor/nucleo/css/nucleo.css";
import "@/assets/vendor/font-awesome/css/font-awesome.css";
import "@/assets/scss/argon.scss";
import globalComponents from "./globalComponents";
import globalDirectives from "./globalDirectives";
import VueLazyload from "vue-lazyload";
import Snotify from 'vue-snotify';
import 'vue-snotify/styles/material.css';
import { FormTagsPlugin } from 'bootstrap-vue';

export default {
    install(Vue) {
        Vue.use(FormTagsPlugin);
        Vue.use(globalComponents);
        Vue.use(globalDirectives);
        Vue.use(VueLazyload);
        Vue.use(Snotify);
  }
};
