const messages = {

    // English -----------------
    en: {
        //global: {
        //    close: 'Close',
        //    learn: 'Learn more',
        //    meter: 'M²',
        //    address: 'Address',
        //    bio: 'Write a little about yourself here.',
        //    currency: {
        //        dollar: '$',
        //        real: 'R$',
        //    },
        //    submit: 'Submit',
        //    cancel: 'Cancel',
        //    notification: {
        //        button: 'See',
        //    },
        //    type: {
        //        realtor: 'realtor | realtors',
        //        requestor: 'requestor | requestors'
        //    }

        //},
        //title: {
        //    Landing: 'Home',
        //    dashboard: 'Dashboard',
        //    profile: 'Profile',
        //    map: 'Map',
        //    reply: 'Reply',
        //    request: 'Request',
        //    notification: 'Notification',
        //    response: 'Response',
        //    credit: 'Credit',
        //    setting: 'Settings',
        //    feedback: 'Feedback',
        //    report: 'Report',
        //    reset: 'Reset',
        //    register: 'Register',
        //    login: 'Login',
        //},
        //realtor: 'Realtor',
        //requestor: 'Requestor',
        //slogan: 'Find your place, your way',
        //navbar: {
        //    login: 'Login',
        //    password: 'Password',
        //    popover: {
        //        request: 'Request',
        //        reply: 'Requests',
        //        profile: 'Profile',
        //        notification: 'Notifications',
        //        remember: 'Remember me',
        //    },
        //    dropdown: {

        //        request: 'Make a request',
        //        reply: 'Reply a resquet',
        //        credit: 'Buy credit',
        //        profile: 'See my profile',
        //        dashboard: 'Go to the dashboard',
        //        notification: 'Check notifications',
        //        setting: 'Change settings',
        //        feedback: 'Give feedback',
        //        report: 'Report error',
        //        logout: 'Logout',
        //    }
        //},
        //register: {
        //    realtor: "I'm a realtor",
        //    requestor: "I'm looking for a property",
        //    name: 'Name',
        //    number: 'Phone number',
        //    password: 'Password',
        //    confirmation: 'Password confirmation',
        //    city: 'City',
        //    agency: {
        //        yes: 'Yes',
        //        no: 'No',
        //        question: 'Do you work for an agency?',
        //        name: 'Agency name',
        //        website: 'Agency website',
        //    },
        //    policy: {
        //        agreement: 'I agree with the',
        //        link: 'Privacy Policy'
        //    },
        //    submit: 'Create account',
        //    popover: {
        //        email: "Insert your main e-mail. It's where you'll be notified about {type}' responses"
        //    },
        //},
        //landing: {
        //    call: 'Where do you want your property?',
        //    button: 'Request property',
        //    realtor: 'realtor | realtors',
        //    requestor: 'who are looking for a property',
        //    presentation: {
        //        requestor: {
        //            intro: {
        //                title: {
        //                    p1: 'Um jeito novo',
        //                    p2: 'de encontrar imóveis',
        //                },
        //                p1: 'Immobl é uma plataforma imobiliária baseada em solicitações, diferente de tudo que existe hoje. Inovamos ao permitir que você possa fazer solicitações de imóveis e receber ofertas de corretores de qualquer lugar, de graça.'
        //            },
        //            request: {
        //                title: 'Sua solicitação',
        //                p1: 'Em vez de procurar o imóvel que você quer de forma incansável em várias plataformas, contatar vários corretores e repetir as mesmas perguntas, com a Immobl você faz apenas uma solicitação.',
        //                p2: 'Na sua solicitação, de compra ou aluguel, você descreve todos os detalhes importantes do imóvel que está buscando, como tipo, localização e faixa de preço.'
        //            },
        //            benefit: {
        //                title: 'Você no controle',
        //                p1: 'Corretores cadastrados na Immobl que atuam na cidade que você escolheu podem ver sua solicitação e te responder com uma oferta.',
        //                p2: 'Você recebe ofertas que se encaixam no que você busca',
        //                p3: 'Você escolhe a melhor oferta de imóvel para você',
        //                p4: 'O corretor da oferta que você escolhe te contata e vocês continuam a negociação'
        //            },
        //            call: {
        //                title: 'Solicite agora',
        //                p1: 'Crie sua conta e faça uma solicitação com as especificações do imóvel que você procura, do seu jeito e de graça, e surpreenda-se com a rapidez e a facilidade de encontrar o seu lugar com a Immobl.'
        //            }
        //        },
        //        realtor: {
        //            benefit: {
        //                title: 'Feito para corretores',
        //                p1: 'Aproveite os benefícios de negociações centradas no cliente e aumente sua taxa de conversão.',
        //                p2: 'Com a Immobl, você consegue entender o perfil dos clientes de forma estruturada e escolher quais deseja atender, com base nas solicitações deles.',
        //                p3: 'Você foca no principal e ganha tempo, porque você sabe exatamente o que os clientes querem e pode oferecer o melhor que você tem para cada um deles.'
        //            },
        //            call: {
        //                title: 'É corretor?',
        //                p1: 'Então conecte-se com clientes de forma assertiva e evite frustrações. Nossa plataforma aumenta a probabilidade de você e o cliente falarem com a pessoa certa e assim fecharem o melhor negócio possível. Fácil e objetivo, Immobl te ajuda a ir direto ao ponto. Crie sua conta e comece a responder agora mesmo a solicitações.'
        //            }
        //        }
        //    },
        //    text: {
        //        requestor: {
        //            p1: "Discover a new way to find properties. Immobl is a request-based real estate platform, unlike anything that exists today. We innovate by allowing you, {requestor}, to receive responses from {realtor} from anywhere, upon your request.",
        //            p2: "Control the offers you receive and choose to contact the realtor who has the property you want, for rent or purchase. Create an account and make now a request with the specifications of the property you are looking for, your way and for free, and be surprised by the speed and ease of finding your place with Immobl.",
        //        },
        //        realtor: {
        //            p1: "Are you a {realtor}? So enjoy the benefits of customer-centric negotiations and increase your conversion rate. With Immobl, you can understand the profile of customers in a structured way, based on their requests, and thus offer the best you have for each of them. When responding to requests, you focus on what's really important and save time.",
        //            p2: "Connect with customers assertively and avoid frustrations. Instead of lengthy conversations with no results, our platform increases the likelihood that you and the customer will speak to the right person and thus close the best possible deal. Easy and objective, Immobl helps you get direct to the point. Create your account and start responding to requests."
        //        }
        //    }
        //},
        //login: {
        //    password: 'Password',
        //    remember: 'Remember me',
        //    login: 'Log in',
        //    reset: 'Forgot the password?',
        //    register: 'Create new account',
        //},
        //reset: {
        //    login: 'Remembered?',
        //    register: 'Create new account',
        //    submit: 'Send me a new password',
        //},
        //request: {
        //    request: 'Request',
        //    call: 'Fill the form and send your request.',
        //    rent: 'Rent',
        //    buy: 'Buy',
        //    submit: 'Submit',
        //    register: 'Register to send this request',
        //    learn: {
        //        title: "Immobl it's thought for you",
        //    },
        //    tooltip: {
        //        currency: '$',
        //        meter: 'm²',
        //        month: 'month | months'
        //    },
        //    popover: {
        //        static: 'Attention: marking this option can decrease the number of offers this request can receive'
        //    },
        //    label: {
        //        property: 'What type?',
        //        location: 'Location',
        //        type: {
        //            rental: 'Rental price',
        //            total: 'Total price',
        //        },
        //        price: 'Price',
        //        area: 'Area',
        //        time: 'Time of stay',
        //        furnished: 'Furnished',
        //        suite: 'How many suites?',
        //        room: 'How many rooms?',
        //        parking: 'How many parking spaces?',
        //        floor: 'How many floors?',
        //        pet: 'Pet allowed?',
        //        feature: 'Features',
        //        detail: 'Any extra detail',
        //        static: 'Receive only responses matching exactly the specifications',
        //        tag: 'Example: air conditioner, pool',
        //    },
        //    sublabel: {
        //        generic: {
        //            any: 'Any',
        //        },
        //        pet: {
        //            whatever: 'Whatever',
        //            yes: 'Yes',
        //            no: 'No',
        //        },
        //        property: {
        //            all: {
        //                group: 'All',
        //                any: 'Any',
        //            },
        //            main: {
        //                group: 'Main',
        //                apartment: 'Apartment',
        //                house: 'House',
        //                office: 'Office',
        //                store: 'Store',
        //            },
        //            residential: {
        //                group: 'Residential',
        //                apartment: 'Apartment',
        //                condo: 'Condo',
        //                house: 'House',
        //                penthouse: 'Penthouse',
        //                kitchenette: 'Kitchenette',
        //                land: 'Land',
        //                loft: 'Loft',
        //                studio: 'Studio',
        //                ranch: 'Ranch',
        //                farm: 'Farm'
        //            },
        //            commercial: {
        //                office: 'Office',
        //                shed: 'Shed',
        //                group: 'Commercial',
        //                garage: 'Parking space',
        //                warehouse: 'Warehouse',
        //                store: 'Store',
        //                studio: 'Studio',
        //            },
        //        },
        //    }
        //},
        //reply: {
        //    name: 'Reply',
        //    rent: 'rent',
        //    buy: 'buy',
        //    register: 'Create an account so you can answer this request',
        //    tooltip: {
        //        currency: '$',
        //        meter: 'm²',
        //        month: 'month | months'
        //    },
        //    map: {
        //        map: 'Map',
        //        request: 'Requests'
        //    },
        //    label: {
        //        address: 'Enter the address',
        //        property: 'What type?',
        //        price: 'Price',
        //        condominium: 'Condominium',
        //        total: 'Total',
        //        area: 'Area',
        //        time: 'Time of stay',
        //        furnished: 'Furnished',
        //        pet: 'Pet allowed?',
        //        suite: 'Suite',
        //        room: 'Room',
        //        garage: 'Garage',
        //        floor: 'Floors',
        //        feature: 'Features',
        //        availability: 'Ready for move in',
        //        available: 'Day in which it will be available',
        //        visit: 'Days available for visiting',
        //        tax: 'Total amount of one-time extra taxes',
        //        document: 'All necessary documentation',
        //        condition: 'Necessary conditions to {type} the property',
        //        special: 'Any special condition, if applicable',
        //        detail: 'Anything else you find relevant',
        //        images: 'Upload images',
        //        submit: 'Submit',
        //        tag: 'Example: air conditoner, pool',
        //    },
        //    sublabel: {
        //        address: {
        //            street: 'Street',
        //            number: 'Number',
        //            sublocality: 'Sublocality',
        //            city: 'City',
        //            state: 'State',
        //            zip: 'ZIP',
        //        },
        //        generic: {
        //            any: 'Any',
        //        },
        //        pet: {
        //            yes: 'Yes',
        //            no: 'No',
        //        },
        //        property: {
        //            all: {
        //                group: 'All',
        //                any: 'Choose type',
        //            },
        //            main: {
        //                group: 'Main',
        //                apartment: 'Apartment',
        //                house: 'House',
        //                store: 'Store',
        //                office: 'Office',
        //            },
        //            residential: {
        //                group: 'Residential',
        //                apartment: 'Apartment',
        //                condo: 'Condo',
        //                house: 'House',
        //                penthouse: 'Penthouse',
        //                kitchenette: 'Kitchenette',
        //                land: 'Land',
        //                loft: 'Loft',
        //                studio: 'Studio',
        //                farm: 'Farm',
        //                ranch: 'Ranch',
        //            },
        //            commercial: {
        //                office: 'Office',
        //                shed: 'Shed',
        //                group: 'Commercial',
        //                garage: 'Parking space',
        //                warehouse: 'Warehouse',
        //                store: 'Store',
        //                studio: 'Studio',
        //            },
        //        },
        //        visit: {
        //            limited: 'Monday to friday in business time',
        //            all: 'Every day in business time',
        //            specific: 'Specific day',
        //            online: 'Only online tour',
        //        },
        //    },

        //    request: {
        //        feature: 'Features',
        //        warning: {
        //            static: 'This request only accepts offers within the specifications',
        //            dynamic: 'This request also accepts offers close to the specifications',
        //            prefilled: 'The form was conveniently prefilled according to them',
        //        },
        //        type: {
        //            rent: 'rent',
        //            buy: 'buy',
        //        },
        //        price: {
        //            rental: 'rental',
        //            total: 'total',
        //        },
        //        want: ' wants to ',
        //        article: ' one ',
        //        any: ' a ',
        //        connector: 'and',
        //        property: {
        //            any: 'property',
        //            apartment: 'apartment',
        //            house: 'house',
        //            store: 'store',
        //            condo: 'condo',
        //            penthouse: 'penthouse',
        //            kitchenette: 'kitchenette',
        //            land: 'land',
        //            loft: 'loft',
        //            studio: 'studio',
        //            office: 'office',
        //            shed: 'shed',
        //            garage: 'garage',
        //            warehouse: 'warehouse',
        //            farm: 'farm',
        //            ranch: 'ranch',
        //        },
        //        time: ' for {time} ',
        //        in: ' in ',
        //        month: 'month | months',
        //        location: ' in {location},',
        //        with: ', with {price} price ranging from ${minamount} to ${maxamount} and area from {minarea} m² to {maxarea} m²',
        //        furnished: ', furnished',
        //        floor: ' | , {quantity} floor | , {quantity} floors',
        //        suite: ' | , {quantity} suite | , {quantity} suites',
        //        room: ' | , {quantity} room | , {quantity} rooms',
        //        garage: ' | , {quantity} parking space | , {quantity} parking spaces',
        //        pet: ' | , pets allowed | , pets not allowed',
        //    }
        //},

        //balance: {
        //    lack: "You don't have credit",
        //    have: 'You have {balance}',
        //    credit: 'credit | credits',
        //    buy: 'Get more',
        //},
        //credit: {
        //    title: 'credits',
        //    subtitle: '/credit',
        //    currency: 'dollars',
        //    choose: 'Choose',
        //    valid: 'Valid for one month',
        //    basic: {
        //        name: 'Basic',
        //        price: '10',
        //        quantity: '10',
        //    },
        //    advanced: {
        //        name: 'Advanced',
        //        price: '35',
        //        quantity: '50',
        //    },
        //    professional: {
        //        name: 'Professional',
        //        price: '50',
        //        quantity: '100',
        //    },
        //},
        //feedback: {
        //    name: 'Feedback',
        //    label: {
        //        subject: 'Subject',
        //        message: 'Message',
        //        submit: 'Submit',
        //    }
        //},
        //report: {
        //    name: 'Report',
        //    label: {
        //        subject: 'Error',
        //        message: 'Description',
        //        submit: 'Report',
        //    }
        //},
        //setting: {
        //    password: 'New password',
        //    confirmation: 'New passoword confirmation',
        //    city: 'City',
        //    agency: {
        //        question: 'Do you work with an agency?',
        //        name: 'Agency name',
        //        website: 'Agency website',
        //        yes: 'Yes',
        //        no: 'No',
               
        //    },
        //    submit: 'Update account',
        //},
        //profile: {
        //    save: 'Save',
        //    cancel: 'Cancel',
        //},
        //response: {
        //    meter: '{meter} m²',
        //    condominium: ' (+ ${condominium} of condominium)',
        //    time: '{quantity} month contract | {quantity} months contract',
        //    availability: 'Ready for move in',
        //    available: 'Available on {date}',
        //    furnished: 'furnished',
        //    floor: '{quantity} floor | {quantity} floors',
        //    suite: '{quantity} suite | {quantity} suites',
        //    room: '{quantity} room | {quantity} rooms',
        //    garage: '{quantity} parking space | {quantity} parking spaces',
        //    pet: 'pets allowed | pets not allowed',
        //    feature: 'Features',
        //    button: {
        //        contact: 'Contact',
        //        reject: 'Reject',
        //        exclude: 'Exclude',
        //    },
        //    reaction: {
        //        realtor: {
        //            contact: 'See contact here',
        //            standby: "Waiting for {name}'s response",
        //            accept: '{name} wants to know more about your offer! {contact}',
        //            reject: '{name} rejected your reply'
        //        },
        //        requestor: {
        //            contact: 'See contact here',
        //            standby: 'Offer made by {name}, awaiting your answer',
        //            accept: 'You accepted to know more about this offer, {name} should contact you soon',
        //            reject: 'You rejected this offer made by {name}',
        //        },
        //    },
        //    contact: {
        //        info: 'Contact info',
        //        interest: '{name} is interested in your offer!',
        //        contact: 'Make contact either through the phone {phone} or through the email {email}. Good luck!'
        //    },
        //},
        //property: {
        //    rent: 'Rent',
        //    buy: 'Buy',
        //    upto: 'Up to ${amount}',
        //    amount: '${minamount} – ${maxamount}',
        //    area: '{minarea} m² – {maxarea} m²',
        //    reply: 'Reply',
        //},
        
        //warning: {
        //    register: {
        //        all: 'You have to fill all fields properly',
        //        policy: 'You need to agree with the Privacy Policy',
        //    },
        //    login: {
        //        as: {
        //            requestor: 'requestor',
        //            realtor: 'realtor',
        //        },
        //        password: 'You have to enter a valid password',
        //        email: 'You have to enter a valid e-mail',
        //        match: "E-mail and password doesn't match a {as} account"
        //    },
        //    request: {
        //        location: 'You have to enter a location',
        //    },
        //    reply: {
        //        address: 'You have to enter the address',
        //        property: 'You have to choose a type',
        //        amount: 'You have to enter a price',
        //        area: 'You have to enter a valid area',
        //        available: 'You have to enter an available date for move in',
        //        date: 'You have to enter at least one specific day for visiting',
        //        document: 'You have to enter the necessary documentation to make the deal',
        //        price: {
        //            rental: "Price can't be higher than ${price}",
        //            total: "Total price can't be higher than ${price}",
        //        },
        //        time: "Time of stay can't be higher than {time}",
        //        minarea: "Area can't be lower than {minarea} m²",
        //        suite: "Suite number can't be lower than {suite}",
        //        room: "Room number can't be lower than {room}",
        //        garage: "Garage number can't be lower than {garage}",
        //        floor: "Floor number can't be lower than {floor}"
        //    },
        //    feedback: {
        //        success: 'Thank you for your feedback',
        //        warning: 'You have to fill at least one field',
        //    },
        //    report: {
        //        success: 'Thank you for your report',
        //        warning: 'You have to fill both fields',
        //    },
        //    reset: {
        //        email: 'You have to enter a valid e-mail',
        //        success: "New password created successfully and sent to your e-mail",
        //        match: "E-mail doesn't match a {as} account",
        //    },
        //    setting: {
        //        alert: 'You have to fill all fields'
        //    },
        //    server: {
        //        login: {
        //            form: 'Form invalid',
        //            generic: 'An error occurred. Try again'
        //        },
        //        register: {
        //            already: {
        //                realtor: "There's already a realtor account with that e-mail",
        //                requestor: "There's already a requestor account with that e-mail",
        //            },
        //            success: 'Account created successfully. Log in',
        //        },
        //        reply: {
        //            credit: 'Not enough credits',
        //        }                
        //    }
        //},

        //notification: {
        //    name: 'Notifications',
        //    dashboard: {
        //        realtor: 'reply to requests',
        //        requestor: 'make requests',
        //        notification: 'your notifications',
        //        profile: 'your profile',
        //        here: 'here',
        //        p1: 'When you {type}, they will appear here. Go {here} to start!',
        //        p2: 'Also, you can check out {notification} and set up {profile}',
        //        popover: 'Each credit is a request you can reply to',
        //    },
        //    notification: "You don't have notifications",
        //    map: 'No requests in this area',
        //    response: "This request doesn't have offers yet. But don't worry, we will send you an e-mail when it does (check your spam folder)"
        //},
        //policy: {
        //    title: 'Privacy Policy',
        //},
        //chart: {
        //    label: {
        //        standby: 'aguardando',
        //        reject: 'rejected',
        //        accept: 'contact | contacts',
        //        convert: 'converted',

        //    }
        //}
    },


    // Portuguese -----------------
    pt: {
        global: {
            close: 'Fechar',
            learn: 'Saiba mais',
            meter: 'M²',
            address: 'Endereço',
            bio: 'Escreva um pouco sobre você aqui.',
            currency: {
                dollar: '$',
                real: 'R$',
            },
            submit: 'Enviar',
            cancel: 'Cancelar',
            notification: {
                button: 'Conferir',
            },
            type: {
                realtor: 'corretor | corretores',
                requestor: 'cliente | clientes'
            },
            import: {
                submit: 'Importar',
                vivareal: 'Importar imóvel do Viva Real',
                message: 'Imóvel importado com sucesso. Revise e complete os campos restantes'
            }
        },
        title: {
            landing: 'Home',
            dashboard: 'Dashboard',
            profile: 'Perfil',
            map: 'Mapa',
            reply: 'Respoder',
            request: 'Solicitações',
            notification: 'Notificações',
            response: 'Atendimento',
            credit: 'Crédito',
            setting: 'Configurações',
            feedback: 'Feedback',
            report: 'Reportar',
            reset: 'Senha',
            register: 'Cadastro',
            login: 'Entrar',
            ad: 'Anunciar',
            preference: 'Preferências',
            find: 'Imóveis',
            property: 'Anúncio'
        },
        slogan: 'Encontre seu lugar, do seu jeito',
        realtor: 'Corretor',
        requestor: 'Cliente',
        navbar: {
            login: 'Entrar',
            password: 'Senha',
            popover: {
                request: 'Solicitação',
                reply: 'Clientes',
                profile: 'Perfil',
                notification: 'Notificações',
                remember: 'Lembrar-me',
            },
            dropdown: {
                remember: 'Lembrar-me',
                request: 'Fazer uma solicitação',
                reply: 'Atender clientes',
                ad: 'Anunciar imóvel',
                preference: 'Editar preferências',
                credit: 'Comprar créditos',
                profile: 'Ver meu perfil',
                dashboard: 'Ir para o dashboard',
                notification: 'Checar notificações',
                setting: 'Mudar configurações',
                feedback: 'Dar feedback',
                report: 'Reportar erro',
                logout: 'Sair',
                property: {
                    realtor: 'Encontrar anúncios',
                    requestor: 'Encontrar imóveis'
                }
            },
        },
        register: {
            realtor: 'Sou corretor',
            requestor: 'Quero um imóvel',
            name: 'Nome',
            number: 'Número de celular',
            password: 'Senha',
            confirmation: 'Confirmação da senha',
            city: 'Cidade',
            agency: {
                yes: 'Sim',
                no: 'Não',
                question: 'Você trabalha com uma imobiliária?',
                name: 'Nome da imobilária',
                website: 'Site da imobiliária',
            },
            policy: {
                agreement: 'Eu concordo com a',
                link: 'Política de Privacidade'
            },
            submit: 'Criar conta',
            popover: {               
                email: "Insira seu principal e-mail. É por onde você será notificado das respostas que receber dos {type}"
            },
        },
        landing: {
            call: 'Onde você quer seu imóvel?',
            button: 'Solicitar imóvel',
            realtor: 'corretor | corretores',
            requestor: 'quem procura um imóvel',
            presentation: {
                requestor: {
                    intro: {
                        title: {
                            p1: 'Um jeito novo',
                            p2: 'de encontrar imóveis',
                        },
                        p1: 'Com a Immobl, você diz como é o imóvel que está buscando e é atendido por corretores que entendem exatamente o que você precisa.'
                    },
                    request: {
                        title: 'Sua solicitação',
                        p1: 'Em vez de procurar o imóvel que você quer de forma incansável em várias plataformas, contatar vários corretores e repetir as mesmas perguntas, com a Immobl você faz apenas uma solicitação.',
                        p2: 'Na sua solicitação, de compra ou aluguel, você descreve todos os detalhes importantes do imóvel que está buscando, como tipo, localização e faixa de preço.'
                    },
                    benefit: {
                        title: 'Você no controle',
                        p1: 'Corretores cadastrados na Immobl que atuam na cidade que você escolheu podem ver sua solicitação e te responder com uma oferta.',
                        p2: 'Você recebe ofertas que se encaixam no que você busca',
                        p3: 'Você escolhe a melhor oferta de imóvel para você',
                        p4: 'O corretor da oferta que você escolhe te contata e vocês continuam a negociação'
                    },
                    call: {
                        title: 'Solicite agora',
                        p1: 'Crie sua conta e faça uma solicitação com as especificações do imóvel que você procura, do seu jeito e de graça, e surpreenda-se com a rapidez e a facilidade de encontrar o seu lugar com a Immobl.'
                    }
                },
                realtor: {
                    benefit: {
                        title: 'Feito para corretores',
                        p1: 'Aproveite os benefícios de negociações centradas no cliente e aumente sua taxa de conversão.',
                        p2: 'Com a Immobl, você entende o perfil dos clientes de forma estruturada e envia sua oferta diretamente para quem você sabe que está procurando o imóvel que você tem.',
                        p3: 'Você foca no principal e ganha tempo, porque você entende exatamente o que os clientes querem e pode oferecer o melhor que você tem para cada um deles.'
                    },
                    call: {
                        title: 'É corretor?',
                        p1: 'Então não espere seus clientes chegarem até você. Na Immobl, nós te levamos até seus clientes. Nossa plataforma te conecta diretamente com usuários que estão querendo imóveis na sua cidade e te deixa escolher quais atender. Não perca mais tempo, feche os melhores negócios. Crie sua conta e tenha acesso a diversos clientes agora mesmo.'
                    }
                }
            },
            text: {
                requestor: {
                    p1: "Descubra uma nova forma de encontrar imóveis. Immobl é uma plataforma imobilária baseada em solicitações, diferente de tudo que existe hoje. Inovamos ao permitir que você, {requestor}, possa receber respostas de {realtor} de qualquer lugar, a partir da sua solicitação.",
                    p2: "Controle as ofertas que recebe e escolha contatar o corretor que tem o imóvel que você quer, para compra ou aluguel. Crie uma conta e faça já uma solicitação com as especificações do imóvel que você procura, do seu jeito e de graça, e surpreenda-se com a rapidez e a facilidade de encontrar o seu lugar com a Immobl.",
                },
                realtor: {
                    p1: "É {realtor}? Então aproveite os benefícios de negociações centradas no cliente e aumente sua taxa de conversão. Com a Immobl, você consegue entender o perfil dos clientes de forma estruturada, com base em suas solicitações, e assim oferecer o melhor que você tem para cada um deles. Ao responder às solicitações, você foca no principal e ganha tempo.",
                    p2: "Conecte-se com clientes de forma assertiva e evite frustrações. No lugar de demoradas conversas sem resultado, nossa plataforma aumenta a probabilidade de você e o cliente falarem com a pessoa certa e assim fecharem o melhor negócio possível. Fácil e objetivo, Immobl te ajuda a ir direto ao ponto. Crie sua conta e comece a responder agora mesmo a solicitações."
                }
            }
        },
        login: {
            password: 'Senha',
            remember: 'Lembrar-me',
            login: 'Entrar',
            reset: 'Esqueceu a senha?',
            register: 'Criar nova conta',
        },
        reset: {
            login: 'Lembrou?',
            register: 'Criar nova conta',
            submit: 'Enviar-me uma nova senha',
        },
        request: {
            request: 'Solicitação',
            call: 'Com a Immobl, você diz como é o imóvel que está buscando e é atendido por corretores que entendem exatamente o que você precisa. Preencha o formulário abaixo e envie sua solicitação agora.',
            rent: 'Alugar',
            buy: 'Comprar',
            submit: 'Enviar solicitação',
            property: 'Encontrar imóveis',
            learn: {
                title: 'Immobl é pensado para você',
            },
            tooltip: {
                currency: 'R$ ',
                meter: 'm²',
                month: 'mês | meses'
            },
            register: 'Cadastre-se para essa solicitação ser enviada e corretores poderem te atender.',
            popover: {
                static: 'Atenção: marcar essa opção pode diminuir o número de ofertas para essa solicitação'
            },
            label: {
                property: 'Qual tipo?',
                location: 'Localização',
                type: {
                    rental: 'Preço do aluguel',
                    total: 'Preço com condomínio',
                },
                price: 'Preço',
                area: 'Área',
                time: 'Duração da estadia',
                furnished: 'Mobiliado',
                suite: 'Quantas suítes?',
                room: 'Quantos quartos?',
                parking: 'Quantas vagas de garagem?',
                floor: 'Qual andar?',
                pet: 'Pet permitido?',
                feature: 'Características imóvel',
                detail: 'Detalhes extras',
                static: 'Receber somente respostas exatamente dentro das especificações',
                tag: 'Exemplo: ar condicionado, piscina',
            },
            sublabel: {
                generic: {
                    any: 'Qualquer',
                },
                pet: {
                    whatever: 'Tanto faz',
                    yes: 'Sim',
                    no: 'Não',
                },
                property: {
                    all: {
                        group: 'Todos',
                        any: 'Qualquer',
                    },
                    main: {
                        group: 'Principal',
                        apartment: 'Apartamento',
                        house: 'Casa',
                        store: 'Loja',
                        office: 'Escritório',
                    },
                    residential: {
                        group: 'Residencial',
                        apartment: 'Apartamento',
                        condo: 'Condomínio',
                        house: 'Casa',
                        penthouse: 'Cobertura',
                        kitchenette: 'Kitchenette',
                        land: 'Terreno',
                        loft: 'Loft',
                        studio: 'Studio',
                        farm: 'Fazenda',
                        ranch: 'Sítio',
                    },
                    commercial: {
                        office: 'Escritório',
                        shed: 'Galpão',
                        group: 'Comercial',
                        garage: 'Garagem',
                        warehouse: 'Armazém',
                        store: 'Loja',
                        studio: 'Studio',
                    },
                },
            },
        },
        reply: {
            name: 'Resposta',
            rent: 'alugar',
            buy: 'comprar',
            call: 'Preencha o formulário abaixo com as especificações do imóvel que você tem que se encaixa nesse perfil e envie agora. Se {client} aceitar o seu imóvel, você receberá seu número de telefone para dar prosseguimento com a negociação.',
            register: 'Crie sua conta de corretor para atender este e outros clientes agora',
            learn: {
                title: 'Não espere seus clientes chegarem até você',
                p1: 'A Immobl é o jeito mais fácil de corretores de imobiliárias e autônomos conseguirem clientes. Nossa plataforma te conecta diretamente com nossos usuários que estão querendo imóveis na sua cidade e te deixa escolher quais atender. Tudo isso sem te cobrar comissão.',
                p2: 'Cada ponto no mapa é um cliente querendo comprar ou alugar um imóvel. Do lado direito, você pode ver os detalhes dos imóveis que eles estão querendo. Por exemplo, um dos clientes poderia estar querendo alugar um imóvel na faixa de R$ 2.500 – R$ 4.000 em São Paulo, de no mínimo 50 m² e 2 quartos, e se você tivesse um imóvel que se encaixa nesse perfil poderia atendê-lo agora. Na Immobl é fácil assim encontrar os clientes certos para você e atingir suas metas. Escolha seus clientes e clique em ATENDER.',
            },
            tooltip: {
                currency: 'R$ ',
                meter: 'm²',
                month: 'mês | meses'
            },
            map: {
                map: 'Ver mapa',
                request: 'Ver clientes',
                search: 'Clientes do mapa',
            },
            label: {
                address: 'Procure com os dados relevantes do endereço',
                property: 'Qual tipo?',
                price: 'Preço',
                condominium: 'Condomínio',
                total: 'Total',
                area: 'Área',
                time: 'Tempo de contrato',
                furnished: 'Mobiliado',
                pet: 'Pet permitido?',
                suite: 'Quantidade de suítes',
                room: 'Quantidade de quartos',
                garage: 'Quantidade de garagens',
                floor: 'Em qual andar?',
                feature: 'Características do imóvel',
                availability: 'Pronto para mudança',
                available: 'Dia em que estará disponível',
                visit: 'Dias disponíveis para visita',
                tax: 'Preço total de taxas extras',
                document: 'Toda a documentação necessária',
                condition: 'Condições necessárias para {type} o imóvel',
                special: 'Qualquer condição especial, se aplicável',
                detail: 'Informações adicionais',
                images: 'Carregar imagens do imóvel',
                submit: 'Enviar imóvel',
                tag: 'Exemplo: ar condicionado, piscina',
            },
            sublabel: {
                address: {
                    street: 'Rua',
                    number: 'Número',
                    sublocality: 'Bairro',
                    city: 'Cidade',
                    state: 'Estado',
                    zip: 'CEP',
                },
                generic: {
                    any: 'Qualquer',
                },
                pet: {
                    yes: 'Sim',
                    no: 'Não',
                },
                property: {
                    all: {
                        group: 'Todos',
                        any: 'Escolher tipo',
                    },
                    main: {
                        group: 'Principal',
                        apartment: 'Apartamento',
                        house: 'Casa',
                        store: 'Loja',
                    },
                    residential: {
                        group: 'Residencial',
                        apartment: 'Apartamento',
                        condo: 'Condomínio',
                        house: 'Casa',
                        penthouse: 'Cobertura',
                        kitchenette: 'Kitchenette',
                        land: 'Terreno',
                        loft: 'Loft',
                        studio: 'Studio',
                        ranch: 'Sítio',
                        farm: 'Fazenda',
                    },
                    commercial: {
                        office: 'Escritório',
                        shed: 'Galpão',
                        group: 'Comercial',
                        garage: 'Garagem',
                        warehouse: 'Armazém',
                        store: 'Loja',
                        studio: 'Studio',
                    },
                },
                visit: {
                    limited: 'De segunda- a sexta-feira em horário comercial',
                    all: 'Todo dia em horário comercial',
                    specific: 'Dia específico',
                    online: 'Somente tour online',
                },
            },

            request: {
                feature: 'Itens desejados para o imóvel',
                warning: {
                    static: 'Esta solicitação aceita somente ofertas estritamente dentro das especificações',
                    dynamic: 'Esta solicitação aceita também ofertas próximas das especificações',
                    prefilled: 'Alguns campos do formulário foram convenientemente preenchidos de acordo com elas, mas você pode alterar',
                },
                type: {
                    rent: 'alugar',
                    buy: 'comprar',
                },
                price: {
                    rental: 'do aluguel',
                    total: 'total',
                },
                want: 'quer ',
                article: ' um imóvel do tipo ',
                any: ' um ',
                connector: 'e',
                property: {
                    type: {
                        rent: 'cliente quer alugar',
                        buy: 'cliente quer comprar',
                    },
                    any: 'imóvel',
                    apartment: 'apartamento',
                    house: 'casa',
                    store: 'loja',
                    condo: 'condomínio',
                    penthouse: 'cobertura',
                    kitchenette: 'kitchenette',
                    land: 'terreno',
                    loft: 'loft',
                    studio: 'studio',
                    office: 'escritório',
                    shed: 'galpão',
                    garage: 'garagem',
                    warehouse: 'armazém',
                    farm: 'fazenda',
                    ranch: 'sítio',
                },
                time: ' por {time} ',
                in: ' em ',
                month: 'mês | meses',
                location: ' em {location},',
                with: ', com o preço {price} variando de R$ {minamount} a R$ {maxamount} e área de {minarea} m² a {maxarea} m²',
                without: ', com o preço {price} de até R$ {maxamount} e área entre {minarea} m² e {maxarea} m²',
                furnished: ', mobiliado',
                floor: '{quantity}° andar',
                suite: ' | , {quantity} suíte | , {quantity} suítes',
                room: ' | , {quantity} quarto | , {quantity} quartos',
                garage: ' | , {quantity} garagem | , {quantity} garagens',
                pet: ' | , pets permitidos | , pets não permitidos',
            }
        },
        dashboard: {
            requestor: 'Sua solicitação de imóvel | Suas solicitações de imóvel',
            realtor: 'Seus clientes atendidos',
        },

        balance: {
            lack: "Você está sem crédito",
            have: 'Você tem {balance}',
            credit: 'crédito | créditos',
            buy: 'Comprar mais',
        },
        credit: {
            title: 'créditos',
            subtitle: ' por crédito',
            currency: 'reais',
            choose: 'Comprar',
            valid: 'Válidos por um mês',
            basic: {
                name: 'Básico',
                price: '50',
                quantity: '10',
            },
            advanced: {
                name: 'Avançado',
                price: '175',
                quantity: '50',
            },
            professional: {
                name: 'Profissional',
                price: '250',
                quantity: '100',
            },
        },
        feedback: {
            name: 'Feedback',
            label: {
                subject: 'Assunto',
                message: 'Mensagem',
                submit: 'Enviar',
            }
        },
        report: {
            name: 'Reportar',
            label: {
                subject: 'Erro',
                message: 'Descrição',
                submit: 'Enviar',
            }
        },
        setting: {
            password: 'Nova senha',
            confirmation: 'Confirmação da nova senha',
            city: 'Cidade',
            agency: {
                question: 'Você trabalha com uma imobiliária',
                name: 'Nome da imobilária',
                website: 'Site da imobiliária',
                yes: 'Sim',
                no: 'Não',
                
            },
            submit: 'Atualizar conta',
        },
        profile: {
            save: 'Salvar',
            cancel: 'Cancelar',
        },
        response: {
            request: {
                call: 'Ative agora sua solicitação pagando apenas o valor único de R$ 5,00 (cinco reais) para ter o controle e a comodidade de poder receber atendimento de diversos corretores.',
                button: 'Ativar solicitação',
                warning: 'Após pagamento, sua solicitação ficará ativa por 1 mês, período em que poderá receber atendimento de corretores que tenham imóveis compatíveis.'
            },
            meter: '{meter} m²',
            condominium: ' (+ R$ {condominium} de condomínio)',
            time: 'Contrato de {quantity} mês | Contrato de {quantity} meses',
            availability: 'Pronto para mudança',
            available: 'Disponível em {date}',
            furnished: 'mobiliado',
            floor: '{quantity}° andar',
            suite: '{quantity} suíte | {quantity} suítes',
            room: '{quantity} quarto | {quantity} quartos',
            garage: '{quantity} garagem | {quantity} garagens',
            pet: 'pets permitidos | pets não permitidos',
            feature: 'Atributos',
            button: {
                contact: 'Contatar',
                reject: 'Rejeitar',
                exclude: 'Excluir',
            },
            reaction: {
                realtor: {
                    contact: 'Veja o contato aqui',
                    standby: "Esperando pela resposta de {name}",
                    accept: '{name} quer saber mais sobre sua oferta! {contact}',
                    reject: '{name} rejeitou sua resposta'
                },
                requestor: {
                    contact: 'Veja o contato aqui',
                    standby: 'Oferta feita por {name}, esperando sua resposta',
                    accept: 'Você aceitou saber mais sobre esta oferta, {name} deve te contatar em breve',
                    reject: 'Você rejeitou esta oferta feita por {name}',
                },
            },
            contact: {
                info: 'Informação de contato',
                interest: '{name} tem interesse na sua oferta!',
                contact: 'Faça contato pelo telefone {phone} ou pelo e-mail {email}.'
            },
        },
        property: {
            invite: 'Você é o corretor deste imóvel? {register}',
            you: {
                rent: 'Você quer alugar',
                buy: 'Você quer comprar'
            },
            rent: 'Aluguel de',
            buy: 'Compra de',
            upto: 'De até R$ {amount}',
            amount: 'De R$ {minamount} a R$ {maxamount}',
            area: 'Entre {minarea} m² e {maxarea} m²',
            reply: 'Atender este cliente',
            register: {
                title: 'Preencha o formulário abaixo para anunciar um imóvel',
                submit: 'Anunciar imóvel',
                call: 'Você receberá o contato de clientes por e-mail ou telefone.',
                label: {
                    time: 'Tempo mínimo de contrato'
                },
                warning: 'Cada anúncio gasta apenas 1 crédito.'
            },
            dashboard: {
                title: 'Seus anúncios de imóvel',
            },
        },

        warning: {
            register: {
                all: 'Você precisa preencher todos os campos apropriadamente',
                policy: 'Você precisa concordar com a Política de Privacidade',
                password: 'A confirmação de senha não corresponde com a senha'
            },
            login: {
                as: {
                    requestor: 'cliente',
                    realtor: 'corretor',
                },
                password: 'Você precisa inserir uma senha válida',
                email: 'Você precisa inserir um e-mail válido',
                match: "E-mail e senha não correspondem a uma conta de {as}"
            },
            request: {
                location: 'Qual é a localização do imóvel que você quer?',
            },
            reply: {
                address: 'Você precisa inserir o endereço',
                property: 'Você precisa escolher um tipo',
                amount: 'Você precisa inserir um preço',
                area: 'Você precisa inserir uma área válida',
                general: 'Você precisa inserir os números corretamente',
                available: 'Você precisa inserir uma data disponível para mudança',
                date: 'Você precisa inserir ao menos uma data para visita',
                document: 'Você precisa especificar toda a documentação necessária para fechar o negócio',
                price: {
                    rental: "O preço não pode ser maior que R$ {price}",
                    total: "O preço total não pode ser maior que R$ {price}",
                },
                time: "O tempo de estadia não pode ser maior que {time}",
                minarea: "A área não pode ser menor que {minarea} m²",
                suite: "O número de suítes não pode ser menor que {suite}",
                room: "O número de quartos não pode ser menor que {room}",
                garage: "O número de garagens não pode ser menor que {garage}",
                floor: "O número de andares não pode ser menor que {floor}"
            },
            preference: {
                success: 'Preferências atualizadas com sucesso'
            },
            feedback: {
                success: 'Obrigado pelo seu feedback',
                warning: 'Você tem que preencher ao menos um campo',
            },
            report: {
                success: 'Obrigado pela sua colaboração. Vamos analisar sua mensagem e tomar as providências cabíveis. Se necessário, entraremos em contato com você',
                warning: 'Você precisa preencher ambos os campos',
            },
            reset: {
                email: 'Você precisa inserir um e-mail válido',
                success: "Nova senha criada com sucesso e enviada para o seu e-mail",
                match: "O e-mail não não corresponde a uma conta de {as}",
            },
            setting: {
                alert: 'Você precisa preencher todos os campos'
            },
            server: {
                login: {
                    form: 'Formulário inválido',
                    generic: 'Ocorreu um erro. Tente novamente'
                },
                register: {
                    already: {
                        realtor: 'Já existe uma conta de corretor com esse e-mail',
                        requestor: 'Já existe uma conta de cliente com esse e-mail',
                    },
                    success: 'Conta criada com sucesso. Faça o login',
                },
                reply: {
                    credit: 'Créditos insuficientes',
                }
            }
        },
        notification: {
            name: 'Notificações',
            dashboard: {
                realtor: 'atender as solicitações de imóvel dos clientes',
                requestor: 'fizer solicitações',
                notification: 'suas notificações',
                profile: 'seu perfil',
                here: 'aqui',
                p1: 'Quando você {type}, elas vão aparecer aqui. Clique {here} para começar!',
                p2: 'Além disso, você pode configurar {profile}',
                popover: 'Você gasta 1 crédito para cada imóvel que anunciar e nenhum crédito para atender clientes'
            },
            notification: 'Você não tem notificações',
            map: {
                empty: 'Sem clientes nesta região. ',
                lost: 'Não encontrou os clientes que procurava? ',
                call: '{register} para ser avisado quando houver clientes aqui.',
                popover: {
                    title: 'Não espere seus clientes chegarem até você',
                    map: {
                        text: 'A Immobl conecta pessoas que estão procurando imóveis com você, corretor. Qual é a faixa de preço dos seus imóveis? E a área? Use o filtro abaixo para encontrar clientes que estão buscando exatamente os imóveis que você tem e atendê-los agora.'
                    },
                    clients: {
                        text: 'Toque neste botão para ver a localidade, a faixa de preço e outros detalhes dos imóveis que os clientes nesta área do mapa estão querendo e escolha quais atender.'
                    },
                    property: {
                        text: 'Toque neste botão para ver detalhes dos imóveis nesta área do mapa.'
                    }
                },
            },
            response: "Esta solicitação está ativa e ainda não recebeu atendimento de corretores. Mas não se preocupe, quando receber nós te mandaremos um e-mail (cheque a sua caixa de spam)",
        },
        preference: {
            name: 'Preferências',
            label: {
                notification: {
                    all: 'Receber e-mail semanal com resumo de novos clientes',
                },
                submit: 'Salvar',
            },
        },        
        policy: {
            title: 'Política de Privacidade',
        },
        chart: {
            label: {
                standby: 'aguardando',
                reject: 'rejeitada | rejeitadas',
                accept: 'contato | contatos',
                convert: 'conversão | conversões',
            }
        },
        call: {
            whatsapp: 'Dúvidas? Fale com a gente!',
        },
        find: {
            type: {
                buy: 'à venda',
                rent: 'para aluguel'
            },
            filter: {
                button: 'Filtrar imóveis',
                submit: 'Filtrar imóveis',
                all: 'imóveis'
            },
            contact: {
                info: 'Informação de contato',
                interest: '{name} está disponível para te atender!',
                text: {
                    whatsapp: 'Olá, {name}. Vi na Immobl {link} seu imóvel da {location} {type} por {price} e gostaria de saber mais sobre ele.'
                }
            },
            map: {
                empty: 'Sem imóveis nesta região. ',
                lost: 'Não achou o imóvel que queria? ',
                call: '{request} e deixe que corretores te atendam.',
                request: 'Faça uma solicitação de imóvel',
                property: 'Ver imóveis',
                search: 'Imóveis do mapa',
            }
        },
        source: {
            vivareal: 'Anúncio original do Viva Real'
        }
    },
}

export default messages