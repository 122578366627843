<template>
  <div class="custom-control custom-radio" :class="[inlineClass, {disabled: disabled}]" style="display: inline;">
      <div v-for="(radio, n) in radios" :key="n">
          {{randStr()}}
          <input style="display: inline-block;"
                 :id="cbId"
                 class="custom-control-input"
                 type="radio"
                 label="radio.label"
                 :value="radio.name"
                 v-model="model" />
          <label :for="cbId" class="custom-control-label">
              {{radio.label}}
          </label>
      </div>
  </div>
</template>
<script>
import { randomString } from "./stringUtils";
export default {
  name: "base-radio",
  props: {
    radios: {
      type: [String, Number, Array],
      description: "Radio label"
      },
      label: String,
    disabled: {
      type: Boolean,
      description: "Whether radio is disabled"
    },
    value: {
      type: [String, Boolean],
      description: "Radio value"
    },
    inline: {
      type: Boolean,
      description: "Whether radio is inline"
    }
  },
  data() {
    return {
      cbId: ""
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    inlineClass() {
      if (this.inline) {
        return `form-check-inline`;
      }
      return "";
    }
  },
  methods: {
      randStr: function() {
          this.cbId = randomString()
      }
  }
};
</script>
