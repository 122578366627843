import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

const Login = () => import('@/views/Login');
const Reset = () => import('@/views/Reset');
const Register = () => import('@/views/Register');
const Map = () => import('@/views/Map');
const Reply = () => import('@/views/Reply');
const Request = () => import('@/views/Request');
const Ad = () => import('@/views/Ad');
const Property = () => import('@/views/Property');
const Find = () => import('@/views/Find');
const Preference = () => import('@/views/Preference');
const Profile = () => import('@/views/Profile');
const Response = () => import('@/views/Response');
const Notification = () => import('@/views/Notification');
const Dashboard = () => import('@/views/Dashboard');
const Landing = () => import('@/views/Landing');
const Setting = () => import('@/views/Setting');
const Feedback = () => import('@/views/Feedback');
const Credit = () => import('@/views/Credit');
const Report = () => import('@/views/Report');
const AppFooter = () => import('@/views/components/AppFooter');

Vue.use(VueRouter)

function routeAccess(to, from, next) {
    const routes = ['login', 'landing', 'reset', 'register'];

    if (to.name === "map" || to.name === "find") {
        document.querySelector("html").classList.add("mapPage");
        document.querySelector("body").classList.add("mapPage");
    } else {
        document.querySelector("html").classList.remove("mapPage");
        document.querySelector("body").classList.remove("mapPage");
    }

    document.querySelector("body").classList.remove("modal-open"); // Without this, Argon's modal's overflow:hidden is not automatically disabled by navigation.    

    if (!store.state.logged_in && !routes.includes(to.name)) {
        if (to.name == 'request' || to.name == 'map' || to.name == 'find' || to.name == 'property') {
            next()
        } else {
            store.state.redirect = to.fullPath;
            next({ path: '/login' })
        }
    } else if (store.state.logged_in && to.query.redirect != undefined) {
        next({ path: to.query.redirect });
    } else if (store.state.logged_in && routes.includes(to.name)) {
        next({ path: '/dashboard' })
    } else if (store.state.logged_in) {
        if ((store.state.type == 'realtor' && to.name == 'request') || (store.state.type == 'requestor' && (to.name == 'map' || to.name == 'reply' || to.name == 'preference'))) {
            next({ path: '/dashboard' })
        } else {
            next()
        }
    } else {
        next()
    }
}

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'landing',
            components: {
                default: Landing,
                footer: AppFooter,
            },
            beforeEnter: (to, from, next) => routeAccess(to, from, next),
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/reset',
            name: 'reset',
            component: Reset,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/setting',
            name: 'setting',
            component: Setting,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/property/',
            name: 'find',
            component: Find,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/property/:property_id',
            name: 'property',
            component: Property,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/reply',
            name: 'map',
            component: Map,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/reply/:request_id',
            name: 'reply',
            component: Reply,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/request',
            name: 'request',
            component: Request,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/preference',
            name: 'preference',
            component: Preference,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/ad',
            name: 'ad',
            component: Ad,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/profile/:id',
            name: 'profile',
            component: Profile,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/response/:response_id',
            name: 'response',
            component: Response,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/credit',
            name: 'credit',
            component: Credit,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/notification',
            name: 'notification',
            component: Notification,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/feedback',
            name: 'feedback',
            component: Feedback,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '/report',
            name: 'report',
            component: Report,
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        },
        {
            path: '*',
            redirect: '/dashboard',
            beforeEnter: (to, from, next) => routeAccess(to, from, next)
        }
    ],
    //scrollBehavior(to) {
    //    if (['request', 'reply'].includes(to.name)) {
    //        return { x: 0, y: 0 }
    //    }
    //}
})