<template>
    <div id="app">
        <b-navbar style="z-index: 99999999999999; border-radius: 0px; color:#5271ff;" class="form-control input-group-alternative" sticky>
            <div class="container">
                <b-navbar-brand :to="this.$store.state.logged_in ? '/dashboard' : '/'"><span class="logo" style="color:#5271ff; text-transform: none">Immobl</span></b-navbar-brand>

                <!--<b-nav-form>
                <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
            </b-nav-form>-->

                <b-navbar-nav v-if="this.$store.state.logged_in" class="ml-auto">
                    <b-navbar-nav>
                        <b-nav-item v-if="this.$store.state.type == 'requestor'" v-b-popover.hover.bottom="{content: $t('navbar.popover.request'), disabled: !desktop}" to="/request"><i style="color:#5271ff;" class="ni ni-square-pin ni-lg"></i></b-nav-item>
                        <b-nav-item v-if="this.$store.state.type == 'realtor'" v-b-popover.hover.bottom="{content: $t('navbar.popover.reply'), disabled: !desktop}" to="/reply"><i style="color:#5271ff;" class="ni ni-pin-3 ni-lg"></i></b-nav-item>
                        <b-nav-item v-if="this.$store.state._id" v-b-popover.hover.bottom="{content: $t('navbar.popover.profile'), disabled: !desktop}" :to="{name: 'profile', params: {id: this.$store.getters.getId}}"><i style="color:#5271ff;" class="ni ni-single-02 ni-lg"></i></b-nav-item>
                        <b-nav-item v-if="this.$store.state.logged_in" v-b-popover.hover.bottom="{content: $t('navbar.popover.notification'), disabled: !desktop}" to="/notification"><i style="color:#5271ff;" class="ni ni-bell-55 ni-lg"></i></b-nav-item>
                    </b-navbar-nav>

                    <b-nav-item-dropdown v-if="this.$store.state.logged_in" right>
                        <b-dropdown-item v-if="this.$store.state.type == 'requestor'" variant="primary" to="/request">{{$t('navbar.dropdown.request')}}</b-dropdown-item>
                        <b-dropdown-item v-if="this.$store.state.type == 'realtor'" variant="primary" to="/ad">{{$t('navbar.dropdown.ad')}}</b-dropdown-item>
                        <b-dropdown-item v-if="this.$store.state.type == 'realtor'" variant="primary" to="/reply">{{$t('navbar.dropdown.reply')}}</b-dropdown-item>
                        <b-dropdown-item variant="primary" to="/property">{{this.$store.state.type == 'realtor' ? $t('navbar.dropdown.property.realtor') : $t('navbar.dropdown.property.requestor')}}</b-dropdown-item>
                        <b-dropdown-item v-if="this.$store.state.type == 'realtor'" variant="primary" to="/credit">{{$t('navbar.dropdown.credit')}}</b-dropdown-item>
                        <div v-if="this.$store.state.type == 'realtor'" class="dropdown-divider"></div>
                        <b-dropdown-item to="/dashboard" variant="primary">{{$t('navbar.dropdown.dashboard')}}</b-dropdown-item>
                        <b-dropdown-item :to="{name: 'profile', params: {id: this.$store.getters.getId}}" variant="primary">{{$t('navbar.dropdown.profile')}}</b-dropdown-item>
                        <b-dropdown-item to="/notification" variant="primary">{{$t('navbar.dropdown.notification')}}</b-dropdown-item>
                        <b-dropdown-item v-if="this.$store.state.type == 'realtor'" variant="primary" to="/preference">{{$t('navbar.dropdown.preference')}}</b-dropdown-item>
                        <div class="dropdown-divider"></div>
                        <!--                    <b-dropdown-item to="/setting" variant="primary">{{$t('navbar.dropdown.setting')}}</b-dropdown-item>-->
                        <b-dropdown-item to="/feedback" variant="primary">{{$t('navbar.dropdown.feedback')}}</b-dropdown-item>
                        <b-dropdown-item to="/report" variant="primary">{{$t('navbar.dropdown.report')}}</b-dropdown-item>
                        <div class="dropdown-divider"></div>
                        <b-dropdown-item @click="logout" variant="primary">{{$t('navbar.dropdown.logout')}}</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>

                <b-navbar-nav v-if="!this.$store.state.logged_in" class="ml-auto">
                    <!--<b-nav-item-dropdown v-if="desktop">
                    <template v-slot:button-content>
                        <span style="text-transform: capitalize">{{$t(model.as)}}</span>
                    </template>
                    <b-dropdown-item @click="model.as = 'requestor'">{{$t('requestor')}}</b-dropdown-item>
                    <b-dropdown-item @click="model.as = 'realtor'">{{$t('realtor')}}</b-dropdown-item>
                </b-nav-item-dropdown>-->
                    <b-nav-form class="row">
                        <!--<b-form-input v-if="desktop" v-model="model.email" type="email" style="background-color: aliceblue;" size="sm" class="mr-sm-2" placeholder="E-mail"></b-form-input>
    <b-form-input v-if="desktop" v-model="model.password" type="password" style="background-color: aliceblue" size="sm" class="mr-sm-2" :placeholder="$t('navbar.password')"></b-form-input>
    <base-checkbox v-if="desktop" v-model="model.remember" v-b-popover.hover.bottom="$t('navbar.popover.remember')"></base-checkbox>-->
                        <b-button v-if="!['/login', '/request'].includes(this.$route.path) && !['property'].includes(this.$route.name)" size="sm" @click="submitModel()" class="my-2 my-sm-0" variant="outline-primary">{{$t('navbar.login')}}</b-button>
                        <b-button v-if="!['/register', '/request'].includes(this.$route.path) && !['property'].includes(this.$route.name)" to="register" size="sm" class="my-2 my-sm-0" variant="primary">Criar conta</b-button>
                        <b-button v-if="['property'].includes(this.$route.name)" to="/property" size="sm" class="my-2 my-sm-0" variant="primary">{{$t('request.property')}}</b-button>
                        <b-button v-if="['/request'].includes(this.$route.path)" to="/property" size="sm" class="my-2 my-sm-0" variant="primary">{{$t('request.property')}}</b-button>
                        <span class="px-1"></span>
                    </b-nav-form>
                </b-navbar-nav>
            </div>

        </b-navbar>

        <main>
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view :key="$route.fullPath" />
            </fade-transition>
        </main>
        <router-view name="footer"></router-view>
        <vue-snotify></vue-snotify>
        <a v-if="this.$store.state.type == 'realtor' || this.$route.path == '/reply'" @click="$gtag.event('contact_us')" :href="(desktop ? 'https://web' : 'https://api') + '.whatsapp.com/send?phone=5512997531833&text=Oi. Sou corretor e gostaria de saber mais sobre a Immobl'" v-b-popover.hover.top="{content: $t('call.whatsapp')}" class="whatsapp-button shadow" target="_blank">
            <i class="fa fa-whatsapp my-float" style="color: white;"></i>
        </a>
    </div>
</template>

<script>    
    import { FadeTransition } from "vue2-transitions";
    import $ from 'jquery';
    import {
        BNavbar,
        BNavbarBrand,
        BNavbarNav,
        BNavItem,
        BNavForm,
        //BFormInput,
        BButton,
        BNavItemDropdown,
        BDropdownItem,
    } from 'bootstrap-vue'

    export default {
        data() {
            return {
                model: {
                    as: 'requestor',
                    email: '',
                    password: '',
                    remember: true,
                },
                mapSearch: '',
                desktop: screen.width > 750,
            }
        },
        components: {
            FadeTransition,
            BNavbar,
            BNavbarBrand,
            BNavbarNav,
            BNavItem,
            BNavForm,
            //BFormInput,
            BButton,
            BNavItemDropdown,
            BDropdownItem,
        },

        methods: {
            getNotification() {
                var user_id = this.$store.state._id;
                var displayNotification = this.displayNotification;

                if (typeof user_id !== 'undefined') {
                    $.ajax({
                        url: 'https://api.immobl.com/notification?push=true',
                        type: 'GET',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        success: function (response) {
                            if (response.success) {
                                response.notifications.forEach(notification => displayNotification(notification));
                            } else {
                                window.location = '/';
                            }
                        },
                        //error: function (err) {
                        //    console.log(err);
                        //}
                    })
                }
            },

            submitModel() {
                var store = this.$store
                var router = this.$router

                if (!(this.model.password.length < 6 && this.model.email === '')) {
                    $.ajax({
                        url: 'https://api.immobl.com/login',
                        contentType: 'application/json;charset=UTF-8',
                        type: 'POST',
                        xhrFields: {
                            withCredentials: true
                        },
                        crossDomain: true,
                        data: JSON.stringify({ data: this.model }),
                        success: function (response) {
                            if (response.success) {
                                updateStorage(response.success, response.user_data);
                            } else if (response.logged_in) {
                                router.push('/dashboard');
                            } else {
                                updateStorage(false, response.message);
                            }
                        },
                        //error: function (err) {
                        //    console.log(err);
                        //},
                    });
                } else {
                    router.push('login');
                }

                var gtag = this.$gtag;
                function updateStorage(success, data) {
                    if (success) {
                        gtag.set({ 'user_id': data._id });
                        gtag.event('login', { method: 'navbar' })
                        store.replaceState({ ...store.state, ...data });
                        store.dispatch('updateLoginStatus', true);
                        store.dispatch('updateType', data.type);
                        router.push('dashboard');
                    } else {
                        store.state.alert.message = data;
                        store.state.alert.new = true;
                        store.state.alert.type = 'warning',
                        router.push('login');
                    }
                }
            },

            logout() {
                this.$store.state.logged_in = false;
                this.$store.state.type = '';
                $.ajax({
                    async: false,
                    url: 'https://api.immobl.com/logout',
                    type: 'GET',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    //error: function (err) {
                    //    console.log(err);
                    //}
                })

                this.$router.push('/')
            },

            displayNotification(notification) {
                var router = this.$router
                var route = this.$route
                function goToResponse() {
                    if (route.name != 'response') {
                        router.push({ name: 'response', params: { response_id: notification.meta.object_id } })
                    }
                }

                this.$snotify.info(
                    notification.meta.subject,
                    {
                        timeout: 5000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        buttons: [
                            { text: this.$t('global.notification.button'), action: goToResponse, bold: true }
                        ]
                    }
                )
            },
        },

        mounted() {
            var iso2 = this.$store.state.country.iso2;
            if (typeof iso2 != undefined) {
                if (['BR', 'AO', 'PT'].includes(iso2.toUpperCase())) {
                    this.$i18n.locale = 'pt';
                } else {
                    this.$i18n.locale = 'en';
                }
            }
            document.title = 'Immobl - ' + this.$t('slogan');
            setInterval(this.getNotification, 60000);
        }
    };
</script>

<style lang="scss">
    #app {
        font-family: Open Sans, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
    }

    .logo {
        font-family: Work Sans;
        font-size: x-large;
    }

    b-nav-item {
        background-color: #5271ff
    }

    .autocompletePlaceholder::-webkit-input-placeholder {
        color: #8898aa
    }

    .modal-body {
        padding: 0px !important;
    }

    .whatsapp-button {
        position: fixed;
        width: 47px;
        height: 47px;
        bottom: 28px;
        right: 25px;
        background-color: #25d366;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        z-index: 100;
        transition: top ease 0.5s;
    }
</style>
