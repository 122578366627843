<template>
    <div class="form-group"
         :class="[
       {'input-group': hasIcon},
       {'has-danger': error},
       {'focused': focused},
       {'input-group-alternative': alternative},
       {'has-label': label || $slots.label},
       {'has-success': valid === true && showSuccess},
       {'has-danger': valid === false}
       ]">
        <slot :name="label">
            <label v-if="label" :class="labelClasses">
                {{label}}
                <span v-if="required">*</span>
            </label>
        </slot>

        <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
        <span :class="[{'input-group-text': true}]">
          <slot name="addonLeft">
            <i :class="addonLeftIcon"></i>
          </slot>
        </span>
        </div>

        <div v-if="telInput && !addonLeftIcon">
            <vue-tel-input :validCharactersOnly="true"
                           style="border: 0px"
                           inputClasses="form-control"
                           required
                           :placeholder="numberholder"
                           @onInput="emitTel"
                           @blur="onBlur"
                           :autofocus="false"
                           autocomplete="false"
                           aria-describedby="addon-right addon-left"
                           @country-changed="translate">
            </vue-tel-input>
        </div>

        <slot v-if="!telInput" v-bind="slotData">
            <input v-if="isReadonly"
                   v-on:keydown="regexFilter($event, filter)"
                   v-on:keyup="regexFilter($event, filter)"
                   v-on:input="regexFilter($event, filter)"
                   v-on:change="regexFilter($event, filter)"
                   v-on:mouseup="regexFilter($event, filter)"
                   v-on:mousedown="regexFilter($event, filter)"
                   v-on:select="regexFilter($event, filter)"
                   v-on:drop="regexFilter($event, filter)"
                   v-on:context="regexFilter($event, filter)"
                   v-model="interfaceModel"
                   :type="type"
                   :ref="reference"
                   :step="step"
                   :min="min"
                   :max="max"
                   readonly
                   v-on="listeners"
                   v-bind="$attrs"
                   :class="[{'form-control': true}, {'is-valid': valid === true}, {'is-invalid': valid === false}, inputClasses]"
                   aria-describedby="addon-right addon-left">

            <input v-else
                   v-on:keydown="regexFilter($event, filter)"
                   v-on:keyup="regexFilter($event, filter)"
                   v-on:input="regexFilter($event, filter)"
                   v-on:change="regexFilter($event, filter)"
                   v-on:mouseup="regexFilter($event, filter)"
                   v-on:mousedown="regexFilter($event, filter)"
                   v-on:select="regexFilter($event, filter)"
                   v-on:drop="regexFilter($event, filter)"
                   v-on:context="regexFilter($event, filter)"
                   v-model="interfaceModel"
                   :type="type"
                   :ref="reference"
                   :step="step"
                   :min="min"
                   :max="max"
                   v-on="listeners"
                   v-bind="$attrs"
                   :class="[{'form-control': true}, {'is-valid': valid === true}, {'is-invalid': valid === false}, inputClasses]"
                   aria-describedby="addon-right addon-left">

        </slot>
        <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
          <span class="input-group-text">
              <slot name="addonRight">
                  <i :class="addonRightIcon"></i>
              </slot>
          </span>
        </div>
        <slot name="infoBlock"></slot>
        <slot name="helpBlock">
            <div class="text-danger invalid-feedback" style="display: block;" :class="{'mt-2': hasIcon}" v-if="error">
                {{ error }}
            </div>
        </slot>
    </div>
</template>
<script>
    import { VueTelInput } from 'vue-tel-input'

    export default {
        components: {
            VueTelInput
        },
        inheritAttrs: false,
        name: "base-input",
        props: {
            name: String,
            filter: RegExp,
            type: String,
            reference: String,
            step: [String, Number],
            min: [Number, String],
            max: [Number, String],
            isReadonly: {
                Boolean,
                default: false
            },
            required: {
                type: Boolean,
                description: "Whether input is required (adds an asterix *)"
            },
            valid: {
                type: Boolean,
                description: "Whether is valid",
                default: undefined
            },
            alternative: {
                type: Boolean,
                description: "Whether input is of alternative layout"
            },
            label: {
                type: String,
                description: "Input label (text before input)"
            },
            error: {
                type: String,
                description: "Input error (below input)"
            },
            labelClasses: {
                type: String,
                description: "Input label css classes"
            },
            inputClasses: {
                type: String,
                description: "Input css classes"
            },
            value: {
                type: [String, Number],
                description: "Input value"
            },
            addonRightIcon: {
                type: String,
                description: "Addon right icon"
            },
            addonLeftIcon: {
                type: String,
                description: "Addont left icon"
            },
            alert: {
                type: Boolean,
                default: true
            },
            telInput: Boolean,
            showSuccess: {
                type: Boolean,
                default: false,
            },
            numberholder: String,
        },
        data() {
            return {
                focused: false,
            };
        },
        computed: {
            interfaceModel: {
                get() {
                    return this.value
                },
                set(val) {
                    this.$emit('input', val)
                }
            },
            listeners() {
                return {
                    ...this.$listeners,
                    input: this.updateValue,
                    focus: this.onFocus,
                    blur: this.onBlur
                };
            },
            slotData() {
                return {
                    focused: this.focused,
                    ...this.listeners
                };
            },
            hasIcon() {
                const { addonRight, addonLeft } = this.$slots;
                return (
                    addonRight !== undefined ||
                    addonLeft !== undefined ||
                    this.addonRightIcon !== undefined ||
                    this.addonLeftIcon !== undefined
                );
            }
        },
        methods: {
            emitTel(input) {
                this.$emit('tel-input', input);
            },
            updateValue(evt) {
                let value = evt.target.value;
                this.$emit("input", value);
            },
            onFocus(value) {
                this.focused = true;
                this.$emit("focus", value);
            },
            onBlur(value) {
                this.focused = false;
                this.$emit("blur", value);
            },
            regexFilter: function (event, filter = /[\s\S]*/) {
                if (filter.test(event.target.value)) {
                    this.oldValue = event.target.value;
                    this.oldSelectionStart = this.selectionStart;
                    this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                    event.target.value = this.oldValue;
                    event.target.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                }
            },
            translate(country) {
                if (['BR', 'AO', 'PT'].includes(country.iso2)) {
                    this.$i18n.locale = 'pt';
                } else {
                    this.$i18n.locale = 'en';
                }
                document.title = 'Immobl - ' + this.$t('slogan');
            }
        }
    };
</script>
<style>
</style>
