import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Argon from "./plugins/argon-kit";
import VueGtag from "vue-gtag";
import VueI18n from 'vue-i18n';
import messages from './translation';
var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo);
Vue.use(VueI18n);

Vue.use(VueGtag, {
    config: { id: 'UA-158667515-1' },    
}, router);

//window['ga-disable-UA-158667515-1'] = true; // To prevent Gtag from tracking development interecations. Must be commented out when building.


const i18n = new VueI18n({
    locale: 'pt', // set locale
    fallbackLocale: 'pt',
    messages, // set locale messages
})

Vue.config.productionTip = false;

Vue.use(Argon);

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app');


router.beforeEach((to, from, next) => {
    document.title = 'Immobl - ' + i18n.messages[i18n.locale].title[to.name];
    next()
})
