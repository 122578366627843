import Vue from 'vue';
import Vuex from 'vuex';
import $ from 'jquery';

var user_data = {};
var logged_in = false;
$.ajax({
    async: false,
    url: 'https://api.immobl.com/login',
    headers: {
        "X-Frame-Options": "SAMEORIGIN",
        "Content-Type": "application/json;charset=UTF-8"
    },
    type: 'GET',
    xhrFields: {
        withCredentials: true
    },
    crossDomain: true,
    success: function (response) {
        logged_in = response.logged_in;
        if (logged_in) {
            user_data = response.user_data;
        }
    },
    //error: function (err) {
    //    //console.log(err);
    //}
});
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        _id: String,
        logged_in: '',
        type: String,
        alert: {
            type: String,
            message: String,
            new: false,
        },
        redirect: 'dashboard',
        country: {
            iso2: 'br'
        },
        city: {
            location: 'S�o Paulo, SP, Brazil',
            place: '(-23.5505199, -46.6333094)',
            coordinate: {
                lat: -23.5505199,
                lng: -46.6333094
            },
        },
        early_session: {
            request: {
                available: false,
                data: {},
            }
        },
        filter: {
            type: 'rent',
            amount: {
                rent: [0, 30000],
                buy: [0, 4000000]
            },
            area: [0, 650]
        },
    },    

    mutations: {
        UPDATE_USER_ID(state, payload) {
            state._id = payload;
        },

        UPDATE_LOGIN_STATUS(state, payload) {
            state.logged_in = payload;
        },

        UPDATE_TYPE(state, payload) {
            state.type = payload;
        }
    },

    actions: {
        updateUserId(context, user_id) {
            context.commit("UPDATE_USER_ID", user_id);
        },

        updateLoginStatus(context, login_status) {
            context.commit("UPDATE_LOGIN_STATUS", login_status);
        },

        updateType(context, user_type) {
            context.commit("UPDATE_TYPE", user_type);
        },
    },

    getters: {
        getId(state) {
            return state._id;
        },

        getStatus(state) {
            return state.logged_in;
        },

        getType(state) {
            return state.type;
        }
    },

});

store.replaceState({ ...store.state, ...user_data});
store.dispatch('updateUserId', user_data._id);
store.dispatch('updateLoginStatus', logged_in);

export default store
